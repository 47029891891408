import * as type from "./types";
import _ from "lodash";
export default (state = {}, action) => {
  switch (action.type) {
    case type.FETCH_LOAN_EMPLOYEES_DDL:
      return { ..._.mapKeys(action.payload, "value") };
    default:
      return state;
  }
};
